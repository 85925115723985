
import Vue from "vue";
import moment from "moment";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "WeeklyAvailabilityModal",
  props: {
    date: String
  },
  data() {
    return {
      isRendered: true,
      agenda: [] as any,
      startTimes: [
        {
          times: []
        }
      ] as any,
      endTimes: [
        {
          times: []
        }
      ] as any,
      selectedStartTimes: [
        {
          times: []
        }
      ] as any,
      selectedEndTimes: [
        {
          times: []
        }
      ] as any,
      errors: {} as any,
      timeOptions: [] as any,
      i_agree: false,
      // Select icons
      OpenIndicator: {
        render: (createElement: any) =>
          createElement("inline-svg", {
            attrs: {
              src: require(`@/assets/icons/arrow.svg`)
            }
          })
      },
      Deselect: {
        render: (createElement: any) =>
          createElement("b-icon", {
            attrs: {
              icon: "X"
            }
          })
      },
      submitBtnLoading: false
    };
  },
  computed: {
    ...mapGetters({
      termsAndConditionLink: "helper/getTermsAndConditionLink",
      paymentTermsLink: "helper/getPaymentTermsLink",
      platformCurrency: "helper/getDefaultPlatformCurrency"
    })
  },
  watch: {
    date(val) {
      if (val !== "") {
        this.resetData();
      }
    }
  },
  mounted() {
    this.resetData();
  },
  created: function(): void {
    this.$store.dispatch("helper/GET_DEFAULT_PLATFORM_CURRENCY");
  },
  methods: {
    resetData(): void {
      this.errors = {};
      const date = moment(this.date)
        .format("DD/MM/yyyy")
        .toString();
      this.agenda = [
        {
          date,
          times: [
            {
              start_time: "",
              end_time: "",
              price: null
            }
          ]
        }
      ];
      this.selectedStartTimes = this.endTimes = this.selectedEndTimes = [
        {
          times: []
        }
      ];
      this.getTimeOptions(date);
    },
    getTimeOptions(date: string, index = 0, hourIndex = 0) {
      date = moment(date, "DD/MM/yyyy")
        .format("YYYY-MM-DD")
        .toString();
      this.$store.dispatch("agenda/GET_DATE_TIMES", date).then(times => {
        this.startTimes[index].times[hourIndex] = times;
        this.isRendered = false;
        this.$nextTick(() => {
          this.isRendered = true;
        });
      });
    },
    addDays() {
      const date = moment(this.date)
        .add(this.agenda.length, "days")
        .format("DD/MM/yyyy")
        .toString();
      this.startTimes.push({
        times: []
      });
      this.endTimes.push({
        times: []
      });
      this.agenda.push({
        date,
        times: [
          {
            start_time: "",
            end_time: "",
            price: null
          }
        ]
      });
      const index = this.agenda.findIndex((i: any) => i.date == date);
      if (index > -1) {
        this.getTimeOptions(date, index);
      }
    },
    addHours(index: number) {
      const agenda = this.agenda[index];
      this.getTimeOptions(agenda.date, index, agenda.times.length);
      this.agenda[index].times.push({
        start_time: "",
        end_time: "",
        price: null
      });
    },
    deleteHours(hoursIndex: number, index: number) {
      if (this.agenda[index].times.length === 1) {
        this.agenda.splice(index, 1);
        this.startTimes.splice(index, 1);
        this.endTimes.splice(index, 1);
        return;
      }
      this.agenda[index].times.splice(hoursIndex, 1);
    },
    checkSelection(e: any, index: string, type: string) {
      const $vmTime = (this as any)[type];
      return !$vmTime[index]?.times.includes(e.value);
    },
    startTimeChange(time: any, index: any, hourIndex: any) {
      const oldTime = this.agenda[index]?.times[hourIndex].start_time;

      this.addOrRemoveSelectedTime(oldTime, index, "selectedStartTimes");

      this.agenda[index].times[hourIndex].start_time = time;
      this.agenda[index].times[hourIndex].end_time = "";

      const newTimeIndex = this.startTimes[index].times[hourIndex].findIndex(
        (i: any) => i.value === time
      );
      if (newTimeIndex > -1) {
        this.agenda[index].times[hourIndex].end_time = this.startTimes[
          index
        ].times[hourIndex][newTimeIndex + 2].value;
        const times = [...this.startTimes[index].times[hourIndex]];
        times.splice(0, newTimeIndex + 1);
        this.endTimes[index].times[hourIndex] = times;
        this.addOrRemoveSelectedTime(time, index, "selectedStartTimes");
      }
    },
    endTimeChange(time: any, index: any, hourIndex: any) {
      this.agenda[index].times[hourIndex].end_time = time;
      // this.addOrRemoveSelectedTime(time, index, 'selectedEndTimes')
    },
    addOrRemoveSelectedTime(time: string, index: string, timeKey: string) {
      if (time == "") {
        return;
      }
      const $vmTime = (this as any)[timeKey];
      const timeIndex = $vmTime[index]?.times.indexOf(time);
      if (timeIndex > -1) {
        $vmTime[index]?.times.splice(timeIndex, 1);
        return;
      }
      $vmTime[index]?.times.push(time);
    },
    onSubmit() {
      this.submitBtnLoading = true;

      this.errors = {};

      this.$store
        .dispatch("agenda/CREATE_AGENDA", { agenda: this.agenda })
        .then(() => {
          this.$emit("refresh");
          this.submitBtnLoading = false;
          //Hide
          (this.$refs["weekly-availability-modal"] as Vue & {
            hide: () => boolean;
          }).hide();

          this.resetData();
        })
        .catch(error => {
          this.submitBtnLoading = false;
          if (error.response.status === 422) {
            this.errors = error.response.data.errors;
          }
        });
    },
    getFormattedDate(date: string) {
      return moment(date, "DD/MM/yyyy")
        .format("dddd, DD/MM/yyyy")
        .toString();
    },
    hasError(type: string) {
      if (type in this.errors && this.errors[type][0].length > 0) {
        return { error: this.errors[type][0] };
      }
      return null;
    },
    onlyNumbers(evt: any) {
      evt = evt ? evt : window.event;
      const charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
});
